import { Button } from "@9amhealth/shared";
import styled from "@emotion/styled";
import { cubicBezier, motion, Transition } from "framer-motion";
import type { FC } from "react";
import React, { useEffect } from "react";
import HealthSyncBloc from "src/state/HealthSyncBloc/HealthSyncBloc";
import { tracker, useBloc } from "src/state/state";
import healthkitLogo from "src/ui/assets/icons/healthkitApp.svg";
import BlockingLoadingOverlayController from "../BlockingLoadingOverlay/BlockingLoadingOverlayController";
import MediaPlayer from "../MediaPlayer/MediaPlayer";
import ConnectingApp from "./ConnectingApp";
import FitCenterContent from "./FitCenterContent";
import GradientBackground from "./GradientBackground";
import Translate from "../Translate/Translate";
import { TrackEvent } from "src/state/Track/TrackCubit";

const transition: Transition = {
  duration: 0.4,
  ease: cubicBezier(0, 0.55, 0.45, 1)
};

const InstructionsWrap = styled(motion.div)`
  position: relative;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  z-index: 50;
  opacity: 0;

  > section {
    border-radius: 2.375rem 2.375rem 0 0;
    border: 4px solid var(--Greys-White, #fff);
    background: #f2f1f6;
    aspect-ratio: 884/1126;
    position: absolute;
    bottom: -4px;
    height: 370px;
    left: 50%;
    transform: translate(-50%, 0);
    overflow: hidden;

    video-js {
      transform: scale(1.02);
    }
  }

  *[data-playing="true"] {
    --video-backdrop: #f2f1f6;
  }
`;

const InstructionAnimation: FC<{
  children: React.ReactNode;
  show: boolean;
}> = ({ children, show }) => {
  const variant = {
    show: {
      opacity: 1,
      y: 0,
      scale: 1,
      transition: {
        ...transition,
        duration: 0.8
      }
    },
    hide: {
      opacity: 0,
      y: 200,
      scale: 1.3,
      display: "none"
    }
  };

  return (
    <InstructionsWrap
      variants={variant}
      animate={show ? "show" : "hide"}
      transition={transition}
      aria-hidden={!show}
    >
      <section>{children}</section>
    </InstructionsWrap>
  );
};

const InstructionText = styled(motion.div)`
  width: min(100%, calc(375px + 3.2rem));
  margin: 0 auto;
  padding: 1rem 1.6rem;

  ol {
    list-style: none;
    margin: 1rem 0.2rem 1.6rem;
    padding: 0;

    li {
      color: var(--color-charcoal-80);
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      counter-increment: item;
      margin: 0.5em 0;
      padding: 0;

      &:before {
        color: var(--Primary-SunriseOrange, #ffbd70);
        content: counter(item) ".";
        font-size: 1.2em;
        margin-right: 0.5em;
      }
    }
  }
`;

const HealthSyncSetup: FC<{ onCancel: () => void; onComplete: () => void }> = (
  props
) => {
  const [, { requestAuthorization }] = useBloc(HealthSyncBloc);
  const [steps, setSteps] = React.useState<"intro" | "instructions">("intro");

  const handleAuthorize = async () => {
    BlockingLoadingOverlayController.startLoading({
      bg: "transparent"
    });
    try {
      await requestAuthorization();
      BlockingLoadingOverlayController.loadingSuccess();
      props.onComplete();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      BlockingLoadingOverlayController.endLoading();
    }
  };

  useEffect(() => {
    tracker.track("Health Sync Setup Started" as TrackEvent);
  }, []);

  const variants = {
    show: {
      opacity: 1,
      y: 0,
      transition: {
        ...transition,
        duration: 0.8
      }
    },
    hide: {
      opacity: 0,
      y: 100,
      display: "none"
    }
  };

  return (
    <>
      <GradientBackground
        height={steps === "intro" ? "100%" : "380px"}
        transition={transition}
      >
        <FitCenterContent
          transition={transition}
          textAlign="center"
          itemsAlign="center"
          gap="0.6rem"
          width="375px"
          fit
          show={steps === "intro"}
          style={{ position: "absolute" }}
        >
          <ConnectingApp to={{ src: healthkitLogo, alt: "Apple Health" }} />
          <h4>
            <Translate msg="dataSync.setup.title" />
          </h4>
          <p className="color-c-80">
            <Translate msg="dataSync.setup.text" />
          </p>
          <Button theme="charcoal" onPress={() => setSteps("instructions")}>
            <Translate msg="dataSync.button.connect" />
          </Button>
          <Button
            theme="transparent"
            hideArrow
            onPress={() => props.onCancel()}
          >
            <Translate msg="notNow" />
          </Button>
        </FitCenterContent>
        <InstructionAnimation show={steps === "instructions"}>
          <MediaPlayer
            videoId={"984273197"}
            videoProvider="vimeo"
            title=""
            playIcon="simple"
            inline
            fitToContainer
            playing={steps === "instructions"}
            options={{
              loop: true,
              muted: true,
              controls: false
            }}
          />
        </InstructionAnimation>
      </GradientBackground>
      <InstructionText
        variants={variants}
        animate={steps === "instructions" ? "show" : "hide"}
        aria-hidden={steps !== "instructions"}
        transition={transition}
        style={{
          pointerEvents: steps !== "instructions" ? "none" : "auto"
        }}
      >
        <h4>
          <Translate msg="dataSync.instructions.title" />
        </h4>
        <Translate msg="dataSync.instructions.description" markdown />
        <Button center theme="charcoal" onPress={() => void handleAuthorize()}>
          <Translate msg="continue" />
        </Button>
      </InstructionText>
    </>
  );
};

export default HealthSyncSetup;
