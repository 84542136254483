import styled from "@emotion/styled";
import { motion, ValueAnimationTransition } from "framer-motion";
import React, { CSSProperties, FC } from "react";

const FitCenterContentWrap = styled(motion.div)`
  width: 100%;
  display: grid;
  place-items: center;
  padding: 1rem 1.6rem;
  box-sizing: border-box;

  &[data-fit="true"] {
    height: 100%;
  }

  & > div {
    display: flex;
    flex-direction: column;
    gap: var(--gap, 0);
    max-width: var(--max-width, 100%);
  }

  &[data-text-align="left"] {
    text-align: left;
  }

  &[data-text-align="center"] {
    text-align: center;
  }

  &[data-text-align="right"] {
    text-align: right;
  }

  &[data-items-align="left"] > div {
    align-items: flex-start;
  }

  &[data-items-align="center"] > div {
    align-items: center;
  }

  &[data-items-align="right"] > div {
    align-items: flex-end;
  }
`;

const FitCenterContent: FC<{
  textAlign?: "left" | "center" | "right";
  itemsAlign?: "left" | "center" | "right";
  fit?: boolean;
  gap?: string;
  width?: string;
  children: React.ReactNode;
  show?: boolean;
  style?: React.CSSProperties;
  transition?: ValueAnimationTransition;
}> = ({
  textAlign = "left",
  children,
  itemsAlign = "left",
  gap = "0",
  width,
  show = true,
  style = {},
  fit = false,
  transition
}) => {
  const variants = {
    show: {
      opacity: 1,
      y: 0
    },
    hide: {
      opacity: 0,
      y: 200
    }
  };
  return (
    <FitCenterContentWrap
      transition={transition}
      data-text-align={textAlign}
      data-items-align={itemsAlign}
      data-fit={fit ? "true" : "false"}
      variants={variants}
      animate={show ? "show" : "hide"}
      aria-hidden={!show}
      style={
        {
          ...style,
          "--gap": gap,
          "--max-width": width
        } as CSSProperties
      }
    >
      <div>{children}</div>
    </FitCenterContentWrap>
  );
};

export default FitCenterContent;
