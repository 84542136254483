import styled from "@emotion/styled";
import { useAnimate, ValueAnimationTransition } from "framer-motion";
import React, { FC, useEffect } from "react";

const GradientBackgroundWrap = styled.div`
  position: relative;
  overflow: hidden;
  height: 100%;

  > div {
    position: relative;
    z-index: 3;
    height: 100%;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    inset: 0;
    z-index: 1;
  }

  &::before {
    background: linear-gradient(
      48deg,
      #80b6ff -10.82%,
      #f7bde6 35.55%,
      #ffbd70 77.21%
    );
  }

  &::after {
    z-index: 2;
    background: linear-gradient(
      180deg,
      #fffcf3 10.42%,
      rgba(255, 252, 243, 0) 100%
    );
  }
`;

const GradientBackground: FC<{
  children: React.ReactNode;
  height?: string;
  transition?: ValueAnimationTransition;
}> = ({ children, height, transition }) => {
  const [scope, animate] = useAnimate();

  useEffect(() => {
    void animate(
      scope.current,
      {
        height: height ?? "100%"
      },
      transition
    );
  }, [height, scope.current]);

  return (
    <GradientBackgroundWrap ref={scope}>
      <div>{children}</div>
    </GradientBackgroundWrap>
  );
};

export default GradientBackground;
