import styled from "@emotion/styled";
import React, { FC } from "react";
import { IconCircularArrowsRepeatStatus } from "src/constants/icons";
import nineAmLogo from "src/ui/assets/icons/9amHealthApp.svg";

const ConnectingAppWrap = styled.div`
  display: flex;
  gap: 0.8rem;
  margin-bottom: 1rem;

  img {
    width: 4.2rem;
    height: auto;
    aspect-ratio: 1;
    box-shadow: var(--light-shadow);
    border-radius: 24%;
    display: block;
  }

  svg {
    width: 1.6rem;
    height: auto;
  }
`;

const ConnectingApp: FC<{ to: { src: string; alt: string } }> = ({ to }) => {
  return (
    <ConnectingAppWrap>
      <img src={nineAmLogo} alt="9amHealth" />
      <IconCircularArrowsRepeatStatus />
      <img src={to.src} alt={to.alt} />
    </ConnectingAppWrap>
  );
};

export default ConnectingApp;
